import {
  Button,
  ButtonProps,
  Heading,
  HeadingProps,
  Link,
  LinkProps,
  ListItem,
  ListItemProps,
  ListProps,
  OrderedList,
  Text,
  TextProps,
  UnorderedList,
} from '@chakra-ui/react';
import routes from 'app/routes';
import { AnnouncementBadges } from 'components/common/badges/AnnouncementBadges';
import MainContainer from 'components/common/container/MainContainer';
import Section from 'components/common/section/Section';
import SectionContents from 'components/common/section/SectionContents';
import SectionHeading from 'components/common/section/SectionHeading';
import SectionStack from 'components/common/section/SectionStack';
import SectionText from 'components/common/section/SectionText';
import { IoChevronBack } from 'react-icons/io5';
import Markdown from 'react-markdown';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useAnnouncement } from './useAnnouncement';

function ChakraH1(props: HeadingProps) {
  return <Heading as={'h3'} fontSize={'2xl'} my={4} {...props} />;
}
function ChakraH2(props: HeadingProps) {
  return <Heading as={'h4'} fontSize={'xl'} my={4} {...props} />;
}
function ChakraH3(props: HeadingProps) {
  return <Heading as={'h5'} fontSize={'lg'} my={4} {...props} />;
}
function ChakraParagraph(props: TextProps) {
  return <Text fontSize={'md'} my={2} {...props} />;
}
function ChakraLink(props: LinkProps) {
  return <Link color={'chakra-link-color'} {...props} />;
}
function ChakraOl(props: ListProps) {
  return <OrderedList {...props} />;
}
function ChakraUl(props: ListProps) {
  return (
    <UnorderedList
      {...props}
      sx={{
        '.task-list-item': {
          listStyle: 'none',
          ml: -4,
          input: {
            mr: 2,
          },
        },
      }}
    />
  );
}
function ChakraLi(props: ListItemProps) {
  return <ListItem {...props} />;
}

export function ShowAnnouncement() {
  const { id } = useParams();
  const [announcement] = useAnnouncement(id);

  const BackButton = (props: ButtonProps) => (
    <Button
      as={RouterLink}
      to={routes.announcements_list}
      colorScheme="blue"
      variant={'link'}
      leftIcon={<IoChevronBack />}
      {...props}
    >
      一覧に戻る
    </Button>
  );

  return (
    <MainContainer>
      <BackButton mt={4} />
      <SectionStack>
        <Section>
          <AnnouncementBadges
            announcement={announcement}
            stackProps={{ mb: 6 }}
          />
          <SectionHeading>{announcement.title}</SectionHeading>
          <SectionText>
            掲載：{new Date(announcement.created_at).toLocaleDateString()}
          </SectionText>
          <SectionContents>
            <Markdown
              components={{
                h1: ChakraH1,
                h2: ChakraH2,
                h3: ChakraH3,
                p: ChakraParagraph,
                a: ChakraLink,
                ol: ChakraOl,
                ul: ChakraUl,
                li: ChakraLi,
              }}
            >
              {announcement.content}
            </Markdown>
          </SectionContents>
        </Section>
      </SectionStack>
      <BackButton mb={4} />
    </MainContainer>
  );
}
