import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Link,
  SimpleGrid,
  Text,
  ThemeTypings,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTitle } from 'app/hooks/title';
import { useAnnouncements } from 'components/announcements/useAnnouncements';
import { AnnouncementBadges } from 'components/common/badges/AnnouncementBadges';
import MainContainer from 'components/common/container/MainContainer';
import Section from 'components/common/section/Section';
import SectionContents from 'components/common/section/SectionContents';
import SectionHeading from 'components/common/section/SectionHeading';
import SectionStack from 'components/common/section/SectionStack';
import { memo, useMemo } from 'react';
import { IoChevronForward } from 'react-icons/io5';
import Markdown from 'react-markdown';
import { Link as RouterLink } from 'react-router-dom';

const Home = memo(function Home() {
  useTitle('ホーム');
  const usefulSites: {
    name: React.ReactNode;
    link: string;
    description: React.ReactNode[];
    colorScheme?: ThemeTypings['colorSchemes'];
  }[] = [
    {
      name: 'Hupass意見箱',
      link: 'https://forms.gle/xPbcCHt6dnB4Scu29',
      description: [
        'Hupassに関する、意見・要望・問い合わせを送ることができます。',
      ],
      colorScheme: 'orange',
    },
    {
      name: 'iNAZO',
      link: 'https://inazo.hu-jagajaga.com',
      description: ['Hupassが連携している成績データを見ることができます。'],
      colorScheme: 'blue',
    },
    {
      name: '全学補講・休講情報',
      link: 'https://inform.academic.hokudai.ac.jp/webinfo/p/SerchInfo.aspx',
      description: ['全学教育の休講情報が掲載されています。'],
      colorScheme: 'green',
    },
    {
      name: '工学部講義情報',
      link: 'https://www.eng.hokudai.ac.jp/lecinfo/',
      description: ['工学部の講義情報が掲載されています。'],
      colorScheme: 'yellow',
    },
    {
      name: '法学部講義情報',
      link: 'https://www.cam-life.net/kyukou/pc/nolec_view.php?sid=2',
      description: ['法学部の休講情報が掲載されています。'],
      colorScheme: 'teal',
    },
  ];
  const light = useColorModeValue('400', '500');
  const dark = useColorModeValue('600', '700');
  const announcementParams = useMemo(
    () => new URLSearchParams({ page_size: '3' }),
    []
  );
  const { announcements } = useAnnouncements(announcementParams);

  return (
    <MainContainer>
      <SectionStack>
        <Section>
          <SectionHeading>お知らせ</SectionHeading>
          <SectionContents>
            <VStack>
              {announcements.map((announcement) => (
                <Card key={announcement.id} w={'full'}>
                  <CardBody
                    as={RouterLink}
                    to={`/announcements/${announcement.id}`}
                  >
                    <HStack>
                      <Box flex={1}>
                        <AnnouncementBadges
                          announcement={announcement}
                          stackProps={{ mb: 2 }}
                        />
                        <Heading size={'md'} noOfLines={1} mb={2}>
                          {announcement.title}
                        </Heading>
                        <Box
                          noOfLines={2}
                          fontSize={'sm'}
                          color={'chakra-subtle-text'}
                          mb={2}
                        >
                          <Markdown>
                            {announcement.content.substring(0, 1000)}
                          </Markdown>
                        </Box>
                      </Box>
                      <IconButton
                        as={'span'}
                        icon={<IoChevronForward />}
                        aria-label="read more"
                        variant={'link'}
                        colorScheme={'blue'}
                        mr={-4}
                      />
                    </HStack>
                  </CardBody>
                </Card>
              ))}
            </VStack>
          </SectionContents>
        </Section>
        <Section>
          <SectionContents mt={0}>
            <SimpleGrid minChildWidth={'grid.sm'} gap={4}>
              {usefulSites.map(
                ({ name, link, description, colorScheme = 'blue' }, i) => {
                  return (
                    <GridItem key={i}>
                      <Card
                        bgGradient={`linear(to-b, ${colorScheme}.${light}, ${colorScheme}.${dark})`}
                        color={'whitesmoke'}
                        rounded={'2xl'}
                        h={'full'}
                      >
                        <CardBody>
                          <Heading fontSize={'xl'} mb={4}>
                            {name}
                          </Heading>
                          {description.map((el, i2) => {
                            return typeof el === 'string' ? (
                              <Text key={i2} mb={2}>
                                {el}
                              </Text>
                            ) : (
                              el
                            );
                          })}
                        </CardBody>
                        <CardFooter pt={0}>
                          <Button
                            w={'full'}
                            as={Link}
                            href={link}
                            bgColor={'whiteAlpha.200'}
                            color={'white'}
                            _hover={{
                              bgColor: 'whiteAlpha.300',
                            }}
                            _active={{
                              bgColor: 'whiteAlpha.400',
                            }}
                            rightIcon={<IoChevronForward />}
                            isExternal
                          >
                            アクセスする
                          </Button>
                        </CardFooter>
                      </Card>
                    </GridItem>
                  );
                }
              )}
            </SimpleGrid>
          </SectionContents>
        </Section>
      </SectionStack>
    </MainContainer>
  );
});

export default Home;
