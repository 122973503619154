/**
 * ルーティング等
 */
export default {
  boot: '/boot',
  home: 'home',
  landing: '/',
  login: '/login',
  privacy_policy: 'https://hackmd.io/@cfs7kHkXR_2V_ACvnozJeg/rkPeV7ry3',
  search: '/search',
  search_detail: '/search/:id',
  misc: '/misc',
  timetable: '/timetable',
  timetable_detail: '/timetable/:id',
  user_agreement: 'https://hackmd.io/@cfs7kHkXR_2V_ACvnozJeg/HyppQXS1h',
  timeline: '/timeline',
  general: '/general',
  general_result: '/general/result',
  announcement_create: '/announcements/new',
  announcement_detail: '/announcements/:id',
  announcement_edit: '/announcements/:id/edit',
  announcements_list: '/announcements',
};
