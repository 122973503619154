import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  type PopoverContentProps,
} from '@chakra-ui/react';
import routes from 'app/routes';
import { useAnnouncements } from 'components/announcements/useAnnouncements';
import HupassLogo from 'components/common/HupassLogo';
import { useUserInfo } from 'components/common/header/userInfo';
import { useEffect, useMemo } from 'react';
import { FcImport } from 'react-icons/fc';
import {
  IoMailOpen,
  IoMailUnread,
  IoMoonOutline,
  IoPersonOutline,
  IoSunnyOutline,
} from 'react-icons/io5';
import { Link } from 'react-router-dom';

/** HEADER */
export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userInfo, onLogout } = useUserInfo();
  const defaultParams = useMemo(
    () => new URLSearchParams({ page_size: '5' }),
    []
  );
  const { announcements } = useAnnouncements(defaultParams);
  const hasUnreadAnnouncements = announcements.some(
    (announcement) => announcement.is_read === false
  );
  const { toggleColorMode, colorMode } = useColorMode();

  const popoverContentProps: PopoverContentProps = {
    boxShadow: '2xl',
    borderRadius: 'xl',
    border: 'none',
  };

  useEffect(() => {
    document
      .querySelector('meta[name=theme-color]')
      ?.setAttribute('content', colorMode === 'light' ? '#ffffff' : '#1a202c');
  }, [colorMode]);

  return (
    <Box
      as="header"
      pos="fixed"
      top={0}
      left={0}
      right={0}
      pt="env(safe-area-inset-top)"
      zIndex="docked"
      bgColor="chakra-body-bg"
    >
      <Box
        pl="env(safe-area-inset-left)"
        pr="env(safe-area-inset-right)"
        borderBottom="1px"
        borderColor="chakra-border-color"
        h={{ base: 14, md: 16 }}
      >
        <HStack h="full" px={4}>
          <Link to={routes.landing}>
            <HupassLogo />
          </Link>
          <Flex flex={1} justify="flex-end" direction="row" gap={2}>
            <IconButton
              aria-label="DisplayMode"
              icon={useColorModeValue(<IoMoonOutline />, <IoSunnyOutline />)}
              isRound
              onClick={toggleColorMode}
            />
            <IconButton
              aria-label="Notice"
              icon={hasUnreadAnnouncements ? <IoMailUnread /> : <IoMailOpen />}
              colorScheme={hasUnreadAnnouncements ? 'orange' : 'gray'}
              isRound
              as={Link}
              to={routes.announcements_list}
              variant={'outline'}
            />
            {/** User Menu */}
            <Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
              <PopoverTrigger>
                <IconButton
                  aria-label="User Menu"
                  icon={
                    userInfo ? (
                      <Avatar
                        name={userInfo.username}
                        src={userInfo.picture_url}
                        w="40px"
                        h="40px"
                      />
                    ) : (
                      <IoPersonOutline />
                    )
                  }
                  isRound
                />
              </PopoverTrigger>
              <PopoverContent {...popoverContentProps}>
                <PopoverCloseButton />
                <PopoverBody>
                  <Center>
                    <VStack py={2} gap={4}>
                      {userInfo ? (
                        <>
                          <Avatar size="xl" src={userInfo.picture_url} />
                          <Center flexDirection="column">
                            <Heading>{userInfo.username}</Heading>
                            <Text>{userInfo.email}</Text>
                          </Center>
                          <Button onClick={onLogout}>ログアウト</Button>
                        </>
                      ) : (
                        <>
                          <Icon fontSize="8xl" as={FcImport} />
                          <Heading>ログイン</Heading>
                          <Text>ログインしてデータを同期</Text>
                          <Button as={Link} to={routes.login} onClick={onClose}>
                            ログイン
                          </Button>
                        </>
                      )}
                    </VStack>
                  </Center>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
        </HStack>
      </Box>
    </Box>
  );
}
