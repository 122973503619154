import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import hupassApi from 'app/hupassApi';
import { errorHandler } from 'components/common/toast/toast';
import {
  AnnouncementModel,
  getEmptyAnnouncementModel,
} from 'models/AnnouncementModel';

export function useAnnouncement(id?: string) {
  const [announcement, setAnnouncement] = useState(getEmptyAnnouncementModel());
  const [cookies] = useCookies(['token']);

  useEffect(() => {
    if (!id) return;
    hupassApi
      .getAnnouncement(Number(id), String(cookies.token || ''))
      .then((res) => {
        setAnnouncement(res.data);
        document.dispatchEvent(
          new CustomEvent('onreadannouncement', { detail: String(id) })
        );
      })
      .catch(errorHandler);
  }, [id, cookies.token]);

  const updateAnnouncement = useCallback(
    (announcement: AnnouncementModel) => {
      hupassApi
        .updateAnnouncement(announcement, String(cookies.token || ''))
        .then((res) => setAnnouncement(res.data))
        .catch(errorHandler);
    },
    [cookies.token]
  );

  return [announcement, updateAnnouncement] as const;
}
