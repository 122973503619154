import { Badge, HStack, Icon, StackProps } from '@chakra-ui/react';
import { ANNOUNCEMENT_CATEGORY_NAMES } from 'app/constants';
import { AnnouncementModel } from 'models/AnnouncementModel';
import { IoFolder, IoMail, IoTime } from 'react-icons/io5';

export type AnnouncementBadgesProps = {
  announcement: AnnouncementModel;
  stackProps?: StackProps;
};

export function AnnouncementBadges(props: AnnouncementBadgesProps) {
  const { announcement, stackProps } = props;

  return (
    <HStack {...stackProps}>
      {announcement.is_read === false && (
        <Badge colorScheme="red" display={'flex'} alignItems={'center'} gap={1}>
          <Icon as={IoMail} />
          未読
        </Badge>
      )}
      <Badge
        colorScheme="orange"
        display={'flex'}
        alignItems={'center'}
        gap={1}
      >
        <Icon as={IoFolder} />
        {ANNOUNCEMENT_CATEGORY_NAMES?.[announcement.category]}
      </Badge>
      <Badge display={'flex'} alignItems={'center'} gap={1}>
        <Icon as={IoTime} />
        {new Date(announcement.created_at).toLocaleDateString()}
      </Badge>
    </HStack>
  );
}
