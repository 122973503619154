import {
  Alert,
  AlertIcon,
  Button,
  Card,
  CardBody,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useTitle } from 'app/hooks/title';
import hupassApi, { type SecondLanguage } from 'app/hupassApi';
import routes from 'app/routes';
import MainContainer from 'components/common/container/MainContainer';
import Section from 'components/common/section/Section';
import SectionContents from 'components/common/section/SectionContents';
import SectionHeading from 'components/common/section/SectionHeading';
import SectionStack from 'components/common/section/SectionStack';
import { errorHandler, toast } from 'components/common/toast/toast';
import { useRef, useState } from 'react';
import { FcAddDatabase } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  baseClassState,
  checkboxValuesState,
  departmentState,
  eng1ClassState,
  eng2ClassState,
  secondLanguageClassState,
  secondLanguageState,
  secondLanguageSubclassState,
  studentIdLast2State,
  yearState,
} from './RecoilAtoms';

export default function General() {
  useTitle('全学授業一括登録');
  // Form用の値
  const [year, setYear] = useRecoilState(yearState);
  const [department, setDepartment] = useRecoilState(departmentState);
  const [baseClass, setBaseClass] = useRecoilState(baseClassState);
  const [secondLanguage, setSecondLanguage] =
    useRecoilState(secondLanguageState);
  const [secondLanguageSubclass, setSecondLanguageSubclass] = useRecoilState(
    secondLanguageSubclassState
  );
  const [secondLanguageClass, setSecondLanguageClass] = useRecoilState(
    secondLanguageClassState
  );
  const [eng1Class, setEng1Class] = useRecoilState(eng1ClassState);
  const [eng2Class, setEng2Class] = useRecoilState(eng2ClassState);
  const [studentIdLast2, setStudentIdLast2] =
    useRecoilState(studentIdLast2State);

  const [isDepartmentSelected, setIsDepartmentSelected] = useState(true);
  const [isSecondLanguageSelected, setIsSecondLanguageSelected] =
    useState(true);

  const departmentRef = useRef<HTMLDivElement>(null);
  const secondLanguageRef = useRef<HTMLDivElement>(null);

  // 自然科学実験科目のチェックボックス
  const [checkboxValues, setCheckboxValues] =
    useRecoilState(checkboxValuesState);

  // エラー表示用
  const [isError, setIsError] = useState(false);

  // 送信中のフラグ
  const [pending, setPending] = useState(false);

  const navigate = useNavigate();

  // チェックボックスが変更されたときの処理
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCheckboxValues = {
      ...checkboxValues,
      [event.target.value]: event.target.checked,
    };

    // チェックされた個数をカウント
    const selectedCount =
      Object.values(newCheckboxValues).filter(Boolean).length;

    // 2つ以上のものを選択した場合はエラーを表示
    if (selectedCount > 2) {
      setIsError(true);
      return;
    } else {
      setIsError(false);
    }

    setCheckboxValues(newCheckboxValues);
  };

  const handlePost = (): void => {
    // チェックされた科目の配列を作成
    const scienceExpClass = Object.entries(checkboxValues)
      .filter(([, value]) => value)
      .map(([key]) => key);

    // POST用のdata
    const data = {
      year: Number(year),
      department: Number(department),
      base_class: Number(baseClass),
      second_language: secondLanguage,
      second_language_subclass: Number(secondLanguageSubclass),
      second_language_class: Number(secondLanguageClass),
      eng1_class: Number(eng1Class),
      eng2_class: Number(eng2Class),
      student_id_last2: Number(studentIdLast2),
      science_exp_class: scienceExpClass,
    };

    if (data.department === 0 || secondLanguage === null) {
      setIsDepartmentSelected(data.department !== 0);
      setIsSecondLanguageSelected(secondLanguage !== null);

      if (data.department === 0) {
        departmentRef.current?.scrollIntoView({ behavior: 'smooth' });
      } else if (secondLanguage === null) {
        secondLanguageRef.current?.scrollIntoView({ behavior: 'smooth' });
      }

      toast({
        title: '選択されていない項目があります',
        status: 'error',
      });
      return;
    }

    // 送信中はボタンを無効化
    setPending(true);

    // /api/general/にPOST
    const controller = new AbortController();
    hupassApi
      .GetRequiredClasses(data, controller)
      .then((res) => {
        setPending(false);
        console.log(res.data);
        // 結果画面にdataを渡して遷移
        navigate(routes.general_result, { state: { data: res.data } });
      })
      .catch(errorHandler)
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <MainContainer>
      <SectionStack>
        <Section>
          <SectionHeading>全学授業一括登録</SectionHeading>
          <Alert status="info" rounded="md">
            <AlertIcon />
            <Text>
              この機能は現在ベータ版です。不具合が起きる場合があります。
              もし不具合が起きた場合は、
              <Link
                href="https://forms.gle/xPbcCHt6dnB4Scu29"
                textDecoration={'underline'}
                isExternal
              >
                Hupass意見箱
              </Link>
              からお知らせください。
            </Text>
          </Alert>
          <SectionContents>
            <Card variant={'filled'}>
              <CardBody>
                <VStack gap={6}>
                  <Icon fontSize={'6xl'} as={FcAddDatabase} />
                  <Text color={'chakra-subtle-text'}>
                    クラスによって決められた授業の一覧を表示します。一覧から選択した授業を一括登録することができます。
                  </Text>
                </VStack>
              </CardBody>
            </Card>
          </SectionContents>
        </Section>
        <Section>
          <VStack gap={4}>
            <FormControl isRequired>
              <FormLabel>年度</FormLabel>
              <NumberInput min={2022} value={year} onChange={setYear}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl
              ref={departmentRef}
              isInvalid={!isDepartmentSelected}
              isRequired
            >
              <FormLabel>系</FormLabel>
              <Select
                placeholder="文理を選択"
                onChange={(e) => setDepartment(e.target.value)}
                onBlur={() => setIsDepartmentSelected(department !== '')}
                value={String(department)}
              >
                <option value="1">文系</option>
                <option value="2">理系</option>
              </Select>
              <FormErrorMessage>選択してください</FormErrorMessage>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>基礎クラス</FormLabel>
              <NumberInput
                min={1}
                max={54}
                value={baseClass}
                onChange={setBaseClass}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl
              ref={secondLanguageRef}
              isInvalid={!isSecondLanguageSelected}
              isRequired
            >
              <FormLabel>第二外国語</FormLabel>
              <Select
                placeholder="第二外国語を選択"
                onChange={(e) =>
                  setSecondLanguage(e.target.value as SecondLanguage)
                }
                onBlur={() =>
                  setIsSecondLanguageSelected(secondLanguage !== null)
                }
                value={String(secondLanguage)}
              >
                <option value="de">ドイツ語</option>
                <option value="ch">中国語</option>
                <option value="kr">韓国語</option>
                <option value="fr">フランス語</option>
                <option value="ru">ロシア語</option>
                <option value="es">スペイン語</option>
              </Select>
              <FormErrorMessage>選択してください</FormErrorMessage>
            </FormControl>
            {department === '2' && secondLanguage === 'de' && (
              <FormControl isRequired>
                <FormLabel>理独クラスアルファベット</FormLabel>
                <Select
                  placeholder="理独クラスを選択"
                  onChange={(e) => setSecondLanguageSubclass(e.target.value)}
                  value={String(secondLanguageSubclass)}
                >
                  <option value="1">A</option>
                  <option value="2">B</option>
                  <option value="3">C</option>
                </Select>
              </FormControl>
            )}
            <FormControl isRequired>
              <FormLabel>第二外国語クラス</FormLabel>
              <NumberInput
                min={1}
                value={secondLanguageClass}
                onChange={setSecondLanguageClass}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>英語Ⅰクラス</FormLabel>
              <NumberInput min={1} value={eng1Class} onChange={setEng1Class}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>英語Ⅱクラス</FormLabel>
              <NumberInput min={1} value={eng2Class} onChange={setEng2Class}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>学生番号下2桁</FormLabel>
              <NumberInput
                min={0}
                max={99}
                value={studentIdLast2}
                onChange={setStudentIdLast2}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            {department === '2' && (
              <FormControl isInvalid={isError}>
                <FormLabel>自然科学実験科目</FormLabel>
                <HStack>
                  <Checkbox
                    value="ph"
                    isChecked={checkboxValues.ph}
                    onChange={handleCheckboxChange}
                  >
                    物理
                  </Checkbox>
                  <Checkbox
                    value="ch"
                    isChecked={checkboxValues.ch}
                    onChange={handleCheckboxChange}
                  >
                    化学
                  </Checkbox>
                  <Checkbox
                    value="bi"
                    isChecked={checkboxValues.bi}
                    onChange={handleCheckboxChange}
                  >
                    生物
                  </Checkbox>
                  <Checkbox
                    value="ge"
                    isChecked={checkboxValues.ge}
                    onChange={handleCheckboxChange}
                  >
                    地球惑星科学
                  </Checkbox>
                </HStack>
                <FormErrorMessage>
                  2つ以上選択することはできません
                </FormErrorMessage>
              </FormControl>
            )}
            <Button onClick={handlePost} isDisabled={pending}>
              履修可能な科目を検索
            </Button>
          </VStack>
        </Section>
      </SectionStack>
    </MainContainer>
  );
}
