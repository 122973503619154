import {
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Link,
  Select,
  Switch,
  Text,
} from '@chakra-ui/react';
import { sw } from 'App';
import {
  atomOptionsWithLocalStorage,
  localStorageKeys,
} from 'app/hooks/localStorage';
import { useTitle } from 'app/hooks/title';
import routes from 'app/routes';
import HupassLogo from 'components/common/HupassLogo';
import MainContainer from 'components/common/container/MainContainer';
import Section from 'components/common/section/Section';
import SectionContents from 'components/common/section/SectionContents';
import SectionHeading from 'components/common/section/SectionHeading';
import SectionStack from 'components/common/section/SectionStack';
import SectionSubHeading from 'components/common/section/SectionSubHeading';
import SectionText from 'components/common/section/SectionText';
import { getErrorToastOptions, toast } from 'components/common/toast/toast';
import { memo } from 'react';
import { IoDocumentText, IoOpenOutline, IoReload } from 'react-icons/io5';
import { atom, useRecoilState } from 'recoil';

export const featureClassMemoState = atom(
  atomOptionsWithLocalStorage<'text' | 'markdown' | 'editor.js'>(
    localStorageKeys.featureClassMemo,
    'text'
  )
);

export const featureEnableTodayTabState = atom(
  atomOptionsWithLocalStorage<boolean>(
    localStorageKeys.featureEnableTodayTab,
    false
  )
);

export default memo(function Misc() {
  useTitle('その他');
  const [featureClassMemo, setFeatureClassMemo] = useRecoilState(
    featureClassMemoState
  );
  const [featureEnableTodayTab, setFeatureEnableTodayTab] = useRecoilState(
    featureEnableTodayTabState
  );

  return (
    <MainContainer>
      <SectionStack>
        <Section>
          <Center>
            <HupassLogo size={2} />
          </Center>
        </Section>
        <Section>
          <SectionHeading>バージョン情報</SectionHeading>
          <SectionText>
            <HStack justifyContent={'space-between'}>
              <Text>Version 3.2.0</Text>
              <IconButton
                aria-label="check update"
                onClick={() => {
                  sw &&
                    toast.promise(sw.update(), {
                      success: { title: 'アップデートの確認が終了しました' },
                      loading: { title: 'アップデートの確認中' },
                      error: getErrorToastOptions,
                    });
                }}
              >
                <IoReload />
              </IconButton>
            </HStack>
          </SectionText>
        </Section>
        <Section>
          <SectionHeading>ご利用にあたって</SectionHeading>
          <SectionText>
            Hupassを利用することで以下の利用規約に同意したものとみなします。
          </SectionText>
          <SectionContents
            display={'flex'}
            flexDir={'column'}
            alignItems={'start'}
            gap={1}
            px={1}
          >
            <Button
              as={Link}
              href={routes.privacy_policy}
              isExternal
              variant="link"
              leftIcon={<IoDocumentText />}
              rightIcon={<IoOpenOutline />}
              size={'lg'}
              colorScheme="cyan"
            >
              プライバシーポリシー
            </Button>
            <Button
              as={Link}
              href={routes.user_agreement}
              isExternal
              variant="link"
              leftIcon={<IoDocumentText />}
              rightIcon={<IoOpenOutline />}
              size={'lg'}
              colorScheme="cyan"
            >
              利用規約
            </Button>
          </SectionContents>
        </Section>
        <Section>
          <SectionHeading>試験的な機能</SectionHeading>
          <SectionText>
            以下の機能はバグが含まれていたり、今後のアップデートで廃止されることがあります。
          </SectionText>
          <SectionSubHeading>講義メモ</SectionSubHeading>
          <SectionContents>
            <Select
              flex={1}
              value={featureClassMemo}
              onChange={(e) => {
                setFeatureClassMemo(
                  e.target.value as 'text' | 'markdown' | 'editor.js'
                );
              }}
            >
              <option value={'text'}>テキストエディタ</option>
              <option value={'markdown'}>Markdown</option>
              <option value={'editor.js'} disabled={true}>
                Editor.js - 要望があれば実装
              </option>
            </Select>
          </SectionContents>
          <SectionSubHeading>今日の授業</SectionSubHeading>
          <FormControl display={'flex'} alignItems={'center'}>
            <FormLabel flex={1} color={'chakra-subtle-text'}>
              今日タブを有効化する
            </FormLabel>
            <Switch
              isChecked={featureEnableTodayTab}
              onChange={(e) => setFeatureEnableTodayTab(e.target.checked)}
            />
          </FormControl>
        </Section>
      </SectionStack>
    </MainContainer>
  );
});
