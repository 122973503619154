import {
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
} from '@chakra-ui/react';
import { ANNOUNCEMENT_CATEGORY_NAMES } from 'app/constants';
import MarkdownEditor from 'components/common/form/MarkdownEditor';
import { AnnouncementModel } from 'models/AnnouncementModel';
import React, { useEffect, useState } from 'react';

export type AnnouncementFormProps = {
  announcement: AnnouncementModel;
  onSubmit: (announcement: AnnouncementModel) => void;
  submitButton: React.ReactNode;
};

export function AnnouncementForm(props: AnnouncementFormProps) {
  const { announcement, onSubmit, submitButton } = props;
  const [category, setCategory] = useState<AnnouncementModel['category']>(
    announcement.category
  );
  const [title, setTitle] = useState(announcement.title);
  const [content, setContent] = useState(announcement.content);

  const handleSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    announcement.category = category;
    announcement.title = title;
    announcement.content = content;
    onSubmit(announcement);
  };

  useEffect(() => {
    setTitle(announcement.title);
    setContent(announcement.content);
  }, [announcement]);

  return (
    <VStack as={'form'} onSubmit={handleSubmit} gap={4} alignItems={'stretch'}>
      <FormControl>
        <FormLabel>タイプ</FormLabel>
        <Select
          value={category}
          onChange={(e) => {
            setCategory(e.target.value as AnnouncementModel['category']);
          }}
        >
          {Object.entries(ANNOUNCEMENT_CATEGORY_NAMES).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>タイトル</FormLabel>
        <Input
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>内容</FormLabel>
        <MarkdownEditor value={content} onChange={setContent} />
      </FormControl>
      {submitButton}
    </VStack>
  );
}
