import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Heading,
  Skeleton,
  SkeletonText,
  VStack,
} from '@chakra-ui/react';
import { userInfoState } from 'app/recoil/userInfoState';
import routes from 'app/routes';
import { AnnouncementBadges } from 'components/common/badges/AnnouncementBadges';
import MainContainer from 'components/common/container/MainContainer';
import {
  Pagination,
  PaginationProps,
} from 'components/common/pagination/Pagination';
import Section from 'components/common/section/Section';
import SectionContents from 'components/common/section/SectionContents';
import SectionHeading from 'components/common/section/SectionHeading';
import SectionStack from 'components/common/section/SectionStack';
import SectionText from 'components/common/section/SectionText';
import { getDummyAnnouncementModel } from 'models/AnnouncementModel';
import { useEffect, useMemo } from 'react';
import {
  IoCheckmark,
  IoChevronForward,
  IoDocumentText,
  IoPencil,
} from 'react-icons/io5';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useAnnouncements } from './useAnnouncements';

const PAGE_SIZE = 10;

export function ListAnnouncements() {
  const defaultParams = useMemo(
    () => new URLSearchParams({ page_size: String(PAGE_SIZE) }),
    []
  );
  const {
    announcements,
    pagination,
    fetching,
    fetchAnnouncements,
    fetchAnnouncement,
  } = useAnnouncements(defaultParams);
  const userInfo = useRecoilValue(userInfoState);
  const isSuperUser = userInfo?.is_superuser || false;

  useEffect(() => {
    fetchAnnouncements();
  }, [fetchAnnouncements]);

  const paginationOptions: PaginationProps = {
    total: pagination?.total_pages || 1,
    current: pagination?.current_page || 1,
    onChange: fetchAnnouncements,
    colorScheme: 'blue',
    isDisabled: fetching,
  };

  const handleReadAnnouncement = (id: number) => () => {
    // 未読のお知らせを既読にする処理
    fetchAnnouncement(id);
  };

  return (
    <MainContainer>
      <SectionStack>
        <Section>
          <SectionHeading>お知らせ一覧</SectionHeading>
          {pagination ? (
            <SectionText>
              {pagination?.count}件中{' '}
              {(pagination.current_page - 1) * PAGE_SIZE + 1}-
              {Math.min(
                pagination?.count,
                pagination?.current_page * PAGE_SIZE
              )}
              件目までを表示
            </SectionText>
          ) : (
            <Skeleton height={6} w={60} />
          )}
          <SectionContents>
            <Pagination {...paginationOptions} mb={8} />
            <VStack alignItems={'stretch'} gap={3} divider={<Divider />}>
              {isSuperUser && (
                <Button
                  as={Link}
                  to={routes.announcement_create}
                  leftIcon={<IoDocumentText />}
                  colorScheme="orange"
                  w="full"
                >
                  お知らせを作成する
                </Button>
              )}
              {(fetching
                ? Array.from({ length: PAGE_SIZE }).map(
                    getDummyAnnouncementModel
                  )
                : announcements || []
              ).map((announcement) => (
                <Card key={announcement.id}>
                  <CardBody
                    as={Link}
                    to={routes.announcement_detail.replace(
                      ':id',
                      String(announcement.id)
                    )}
                  >
                    <Skeleton isLoaded={!fetching}>
                      <AnnouncementBadges
                        announcement={announcement}
                        stackProps={{ mb: 4 }}
                      />
                    </Skeleton>
                    <Skeleton isLoaded={!fetching}>
                      <Heading size={'md'} mb={3}>
                        {announcement.title}
                      </Heading>
                    </Skeleton>
                    <SkeletonText
                      noOfLines={3}
                      isLoaded={!fetching}
                      skeletonHeight={4}
                      spacing={3}
                    >
                      <Box noOfLines={3}>
                        <Markdown>
                          {announcement.content.substring(0, 1000)}
                        </Markdown>
                      </Box>
                    </SkeletonText>
                  </CardBody>
                  <CardFooter pt={0} gap={4}>
                    {isSuperUser && (
                      <Button
                        leftIcon={<IoPencil />}
                        as={Link}
                        to={routes.announcement_edit.replace(
                          ':id',
                          String(announcement.id)
                        )}
                        colorScheme="orange"
                        variant={'link'}
                      >
                        編集
                      </Button>
                    )}
                    <Box flexGrow={1} />
                    {announcement.is_read === false && (
                      <Button
                        onClick={handleReadAnnouncement(announcement.id)}
                        colorScheme="green"
                        variant={'link'}
                        leftIcon={<IoCheckmark />}
                      >
                        既読にする
                      </Button>
                    )}
                    <Button
                      as={Link}
                      to={routes.announcement_detail.replace(
                        ':id',
                        String(announcement.id)
                      )}
                      colorScheme="blue"
                      variant={'link'}
                      rightIcon={<IoChevronForward />}
                      isDisabled={fetching}
                    >
                      続きを読む
                    </Button>
                  </CardFooter>
                </Card>
              ))}
            </VStack>
            <Pagination {...paginationOptions} mt={8} />
          </SectionContents>
        </Section>
      </SectionStack>
    </MainContainer>
  );
}
