import { Button, Container, HStack, Heading, Text } from '@chakra-ui/react';
import routes from 'app/routes';
import { reportError } from 'app/utils';
import { memo, useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { IoChevronBack, IoTrash } from 'react-icons/io5';
import {
  Link,
  isRouteErrorResponse,
  useLocation,
  useRouteError,
} from 'react-router-dom';

const isMessageObject = (value: unknown): value is { message: unknown } => {
  return (
    value !== null &&
    typeof value === 'object' &&
    Object.prototype.hasOwnProperty.call(value, 'message')
  );
};

export default memo(function ErrorPage() {
  const [cookies] = useCookies(['user']);
  const location = useLocation();
  const error = useRouteError();
  let title = '不明なエラー';
  let contents = JSON.stringify(error, null, 2);

  if (isRouteErrorResponse(error)) {
    title = `${error.status} ${error.statusText}`;
    if (isMessageObject(error.data) && typeof error.data.message === 'string')
      contents = error.data.message;
  } else if (error instanceof Error) {
    title = error.message;
    contents = error.stack ?? 'No stack trace.';
  }

  useEffect(() => {
    reportError(
      `user: ${cookies.user}
location: ${window.location.href}
userAgent: ${window.navigator.userAgent}
${title}
${contents}`
    ).catch(console.error);
  }, [title, contents, cookies.user, location.pathname]);

  const handleClearLocalStorage = useCallback(() => {
    if (
      confirm(
        'サーバーに保存されていないデータは削除されます。よろしいですか？'
      )
    ) {
      localStorage.clear();
      window.location.reload();
    }
  }, []);

  return (
    <Container my={6}>
      <HStack mb={4}>
        <Button as={Link} to={routes.landing} leftIcon={<IoChevronBack />}>
          トップに戻る
        </Button>
      </HStack>
      <Heading variant="h1" mb={6}>
        {title}
      </Heading>
      <Text
        fontFamily={'mono'}
        color={'green.500'}
        mb={4}
        p={4}
        bgColor={'chakra-subtle-bg'}
        border={'1px'}
        borderColor={'chakra-border-color'}
        rounded={'lg'}
        overflow={'auto'}
      >
        {'// todo もっとこのページを可愛くする'}
      </Text>
      <Text mb={4}>Your User ID:{cookies.user}</Text>
      <Text
        as={'pre'}
        fontFamily={'mono'}
        color={'red.500'}
        mb={4}
        p={4}
        bgColor={'chakra-subtle-bg'}
        border={'1px'}
        borderColor={'chakra-border-color'}
        rounded={'lg'}
        overflow={'auto'}
      >
        {contents}
      </Text>
      <Button
        onClick={handleClearLocalStorage}
        colorScheme={'red'}
        w={'full'}
        leftIcon={<IoTrash />}
      >
        データのリセット
      </Button>
    </Container>
  );
});
