import { Button } from '@chakra-ui/react';
import hupassApi from 'app/hupassApi';
import routes from 'app/routes';
import MainContainer from 'components/common/container/MainContainer';
import Section from 'components/common/section/Section';
import SectionContents from 'components/common/section/SectionContents';
import SectionHeading from 'components/common/section/SectionHeading';
import SectionStack from 'components/common/section/SectionStack';
import { getErrorToastOptions, toast } from 'components/common/toast/toast';
import {
  AnnouncementModel,
  getEmptyAnnouncementModel,
} from 'models/AnnouncementModel';
import { useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { IoChevronBack, IoDocumentText } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import { AnnouncementForm } from './AnnouncementForm';

export function CreateAnnouncement() {
  const announcement = useMemo(getEmptyAnnouncementModel, []);
  const [cookies] = useCookies(['token']);
  const navigate = useNavigate();
  const handleSubmit = (announcement: AnnouncementModel) => {
    if (typeof cookies.token !== 'string') return;
    toast.promise(hupassApi.createAnnouncement(announcement, cookies.token), {
      loading: { title: 'お知らせを作成中...' },
      success: (res) => {
        navigate(routes.announcements_list);
        return {
          title: 'お知らせを作成しました',
          description: `[ID:${res.data.id}] ${res.data.title}`,
        };
      },
      error: getErrorToastOptions,
    });
  };

  return (
    <MainContainer>
      <Button
        colorScheme="blue"
        variant={'link'}
        leftIcon={<IoChevronBack />}
        as={Link}
        to={routes.announcements_list}
        mt={4}
      >
        一覧に戻る
      </Button>
      <SectionStack>
        <Section>
          <SectionHeading>お知らせ作成</SectionHeading>
          <SectionContents>
            <AnnouncementForm
              announcement={announcement}
              onSubmit={handleSubmit}
              submitButton={
                <Button
                  type="submit"
                  colorScheme="orange"
                  leftIcon={<IoDocumentText />}
                >
                  作成
                </Button>
              }
            />
          </SectionContents>
        </Section>
      </SectionStack>
    </MainContainer>
  );
}
