/** CSS */
import './index.css';
/** React */
import React from 'react';
import ReactDOM from 'react-dom/client';
/** React Router Dom */
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
/** React Cookie */
import { CookiesProvider } from 'react-cookie';
/** Recoil */
import { RecoilRoot } from 'recoil';
/** Chakra UI */
import { ChakraProvider } from '@chakra-ui/react';
/** App */
import routes from 'app/routes';
import { defaultOptions } from 'components/common/toast/toast';
import theme from 'theme';
/** Pages */
import { CreateAnnouncement } from 'components/announcements/CreateAnnouncement';
import { EditAnnouncement } from 'components/announcements/EditAnnouncements';
import { ListAnnouncements } from 'components/announcements/ListAnnouncements';
import { ShowAnnouncement } from 'components/announcements/ShowAnnouncement';
import Detail from 'components/detail/Detail';
import General from 'components/general/General';
import Result from 'components/general/Result';
import Home from 'components/home/Home';
import Landing from 'components/landing/Landing';
import Login from 'components/login/Login';
import Misc from 'components/misc/Misc';
import Redirect from 'components/redirect/Redirect';
import Search from 'components/search/Search';
import Timeline from 'components/timeline/Timeline';
import Timetable from 'components/timetable/Timetable';
import ErrorPage from 'ErrorPage';
import App from './App';

const router = createBrowserRouter([
  {
    path: '/',
    id: 'root',
    element: <App />,
    children: [
      {
        path: routes.landing,
        id: 'landing',
        element: <Landing />,
      },
      {
        path: routes.login,
        id: 'login',
        element: <Login />,
      },
      {
        path: routes.home,
        id: 'home',
        element: <Home />,
      },
      {
        path: routes.misc,
        id: 'misc',
        element: <Misc />,
      },
      {
        path: 'calendar',
        id: 'calendar',
        element: <Redirect />,
      },
      {
        path: routes.timeline,
        id: 'timeline',
        element: <Timeline />,
      },
      {
        path: routes.timetable,
        id: 'timetable',
        element: <Timetable />,
      },
      {
        path: routes.timetable_detail,
        id: 'timetable_detail',
        element: <Detail />,
      },
      {
        path: routes.search,
        id: 'search',
        element: <Search />,
      },
      {
        path: routes.search_detail,
        id: 'search_detail',
        element: <Detail />,
      },
      {
        path: routes.general,
        id: 'general',
        element: <General />,
      },
      {
        path: routes.general_result,
        id: 'general_result',
        element: <Result />,
      },
      {
        path: routes.announcement_create,
        id: 'announcement_create',
        element: <CreateAnnouncement />,
      },
      {
        path: routes.announcements_list,
        id: 'announcements_list',
        element: <ListAnnouncements />,
      },
      {
        path: routes.announcement_detail,
        id: 'announcement_detail',
        element: <ShowAnnouncement />,
      },
      {
        path: routes.announcement_edit,
        id: 'announcement_edit',
        element: <EditAnnouncement />,
      },
    ],
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <RecoilRoot>
        <ChakraProvider theme={theme} toastOptions={{ defaultOptions }}>
          <RouterProvider router={router} />
        </ChakraProvider>
      </RecoilRoot>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
