import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  type GridProps,
  type ThemeTypings,
} from '@chakra-ui/react';
import { memo, useCallback, useRef } from 'react';
import {
  IoChevronBack,
  IoChevronForward,
  IoEllipsisHorizontal,
} from 'react-icons/io5';

export type PaginationProps = Omit<GridProps, 'onChange'> & {
  total: number;
  current: number;
  onChange: (current: number) => void;
  colorScheme?: ThemeTypings['colorSchemes'];
  isDisabled?: boolean;
};

export const Pagination = memo(function Pagination(props: PaginationProps) {
  const {
    total,
    current,
    onChange,
    colorScheme,
    isDisabled: isAllDisabled = false,
    ...gridProps
  } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const numOfButtons = Math.min(7, total);
  const isStartExpand = current < 3 || total < 7;
  const isEndExpand = total - current < 4 || total < 7;
  const ref = useRef<HTMLInputElement>(null);

  const handleClick = useCallback(
    (i: number) => () => {
      onChange(i);
    },
    [onChange]
  );

  function PageButton({
    page,
    isDisabled = false,
    index = 1,
  }: {
    page: number;
    isDisabled?: boolean;
    index?: number;
  }) {
    return index <= numOfButtons ? (
      page !== current ? (
        <Button
          onClick={handleClick(page)}
          isDisabled={isAllDisabled || isDisabled}
          rounded={0}
        >
          {isDisabled ? <IoEllipsisHorizontal /> : page}
        </Button>
      ) : (
        <Popover
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          initialFocusRef={ref}
        >
          <PopoverTrigger>
            <Button
              isActive
              rounded={0}
              onClick={onOpen}
              isDisabled={isAllDisabled}
            >
              {page}
            </Button>
          </PopoverTrigger>
          <PopoverContent w="fit-content">
            <PopoverArrow />
            <PopoverBody>
              <FormControl>
                <FormLabel>ページを入力</FormLabel>
                <Input
                  type="number"
                  ref={ref}
                  placeholder={`1-${total}`}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      ref.current?.blur();
                    }
                  }}
                  onBlur={(e) => {
                    const newValue = Math.max(
                      1,
                      Math.min(
                        Math.floor(e.target.valueAsNumber || current),
                        total
                      )
                    );
                    if (newValue === current) return;
                    handleClick(newValue)();
                  }}
                />
              </FormControl>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )
    ) : (
      <></>
    );
  }

  return (
    total > 1 && (
      <ButtonGroup w="full" colorScheme={colorScheme} variant="soft">
        <Grid
          templateColumns={`40px repeat(${numOfButtons}, 1fr) 40px`}
          flex={1}
          {...gridProps}
        >
          <IconButton
            aria-label="previous page"
            borderRightRadius={0}
            onClick={handleClick(current)}
            isDisabled={isAllDisabled || current === 1}
          >
            <IoChevronBack />
          </IconButton>
          <PageButton index={1} page={1} />
          <PageButton index={2} page={2} isDisabled={!isStartExpand} />
          <PageButton
            index={3}
            page={isStartExpand ? 3 : isEndExpand ? total - 4 : current - 1}
          />
          <PageButton
            index={4}
            page={isStartExpand ? 4 : isEndExpand ? total - 3 : current}
          />
          <PageButton
            index={5}
            page={isEndExpand ? total - 2 : isStartExpand ? 5 : current + 1}
          />
          <PageButton index={6} page={total - 1} isDisabled={!isEndExpand} />
          <PageButton index={7} page={total} />
          <IconButton
            aria-label="next page"
            borderLeftRadius={0}
            onClick={handleClick(current + 2)}
            isDisabled={isAllDisabled || current === total}
          >
            <IoChevronForward />
          </IconButton>
        </Grid>
      </ButtonGroup>
    )
  );
});
