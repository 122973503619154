import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  TextareaProps,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { FlatColors } from 'app/colors';
import { type MyClassInfo } from 'app/hupassApi';
import { searchOptionsState } from 'app/recoil/searchOptionsState';
import { PeriodToStr, StrToPeriod } from 'app/utils';
import BottomDrawer from 'components/common/drawer/BottomDrawer';
import BottomDrawerBody from 'components/common/drawer/BottomDrawerBody';
import BottomDrawerHeader from 'components/common/drawer/BottomDrawerHeader';
import AutoResizeTextArea from 'components/common/form/AutoResizeTextArea';
import ColorSelector from 'components/common/form/ColorSelector';
import PeriodForm from 'components/common/form/PeriodForm';
import { getErrorToastDescription, toast } from 'components/common/toast/toast';
import { memo, useEffect, useState } from 'react';
import { IoPencil } from 'react-icons/io5';
import { useRecoilValueLoadable } from 'recoil';

export type InformationEditorProps = {
  myClass: MyClassInfo;
  update: (
    newMyClass: MyClassInfo,
    controller?: AbortController
  ) => Promise<void>;
};

const removeCrLf = (text: string) => {
  return text.replaceAll(/\r?\n/g, '');
};

export default memo(function ClassInformationEditor(
  props: InformationEditorProps
) {
  const { myClass, update } = props;
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();
  const {
    isOpen: isPopoverOpen,
    onOpen: onPopoverOpen,
    onClose: onPopoverClose,
  } = useDisclosure();
  const searchOptionsLoadable = useRecoilValueLoadable(searchOptionsState);
  // form values
  const [subject, setSubject] = useState(myClass.c_subject ?? '');
  const [theme, setTheme] = useState(myClass.c_theme ?? '');
  const [teacher, setTeacher] = useState(myClass.c_teacher ?? '');
  const [periods, setPeriods] = useState([...(myClass.c_periods ?? [])]);
  const [place, setPlace] = useState(myClass.c_place ?? '');
  const [moodleId, setMoodleId] = useState(String(myClass.c_moodle_id ?? ''));
  const [color, setColor] = useState(myClass.color);

  const [doSuggest, setDoSuggest] = useState(true); // 教室の入力候補を表示するかどうかのフラグ

  useEffect(() => {
    setSubject(myClass.c_subject);
    setTheme(myClass.c_theme);
    setTeacher(myClass.c_teacher);
    setPeriods(myClass.c_periods);
    setPlace(myClass.c_place);
    setMoodleId(String(myClass.c_moodle_id ?? ''));
    setColor(myClass.color);
  }, [myClass]);

  const handleClose = () => {
    onDrawerClose();
    setDoSuggest(true);
    if (
      myClass.c_subject !== subject ||
      myClass.c_theme !== theme ||
      myClass.c_teacher !== teacher ||
      myClass.c_periods.toString() !== periods.toString() ||
      myClass.c_place !== place ||
      myClass.c_moodle_id !== (Number(moodleId) || null) ||
      myClass.color !== color
    ) {
      toast.promise(
        update?.({
          ...myClass,
          c_subject: subject,
          c_theme: theme,
          c_teacher: teacher,
          c_periods: periods,
          c_place: place,
          c_moodle_id: Number(moodleId) || null,
          color,
        }),
        {
          success: { title: '授業を更新しました' },
          loading: { title: '授業を更新中' },
          error: (e) => ({
            title: '授業の更新に失敗しました',
            description: getErrorToastDescription(e),
          }),
        }
      );
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const value = e.target.value;
    const urlPattern =
      /https:\/\/moodle.elms.hokudai.ac.jp\/course\/view.php\?id=\d{6}$/g;
    const idPattern = /^\d{6}$/g;
    if (urlPattern.test(value)) {
      setMoodleId(value.slice(-6));
    } else if (idPattern.test(value)) {
      setMoodleId(value);
    } else {
      setMoodleId('');
      toast({
        title: 'Moodle IDの入力に失敗しました',
        description: '正しいURLを入力してください',
        status: 'error',
      });
    }
  };

  const textareaProps: TextareaProps = {
    minH: 0,
    wrap: 'soft',
  };

  return (
    <>
      <Button onClick={onDrawerOpen} variant={'link'} leftIcon={<IoPencil />}>
        編集
      </Button>
      <BottomDrawer isOpen={isDrawerOpen} onClose={handleClose}>
        <BottomDrawerHeader onClose={handleClose}>
          講義情報の編集
        </BottomDrawerHeader>
        <Divider />
        <BottomDrawerBody pt={6}>
          <VStack gap={4}>
            <FormControl>
              <FormLabel>科目名</FormLabel>
              <AutoResizeTextArea
                value={subject}
                onChange={(e) => setSubject(removeCrLf(e.target.value))}
                placeholder={myClass.cls.subject}
                {...textareaProps}
              />
            </FormControl>
            <FormControl>
              <FormLabel>講義題目</FormLabel>
              <AutoResizeTextArea
                value={theme}
                onChange={(e) => setTheme(removeCrLf(e.target.value))}
                placeholder={myClass.cls.theme}
                {...textareaProps}
              />
            </FormControl>
            <FormControl>
              <FormLabel>担当教員</FormLabel>
              <AutoResizeTextArea
                value={teacher}
                onChange={(e) => setTeacher(removeCrLf(e.target.value))}
                placeholder={myClass.cls.teacher}
                {...textareaProps}
              />
            </FormControl>
            <FormControl>
              <FormLabel>教室</FormLabel>
              <Popover
                returnFocusOnClose={false}
                isOpen={isPopoverOpen}
                placement="bottom-start"
                autoFocus={false}
              >
                <PopoverTrigger>
                  <Box>
                    <AutoResizeTextArea
                      value={place}
                      onChange={(e) => setPlace(removeCrLf(e.target.value))}
                      onFocus={() => {
                        if (
                          doSuggest == true &&
                          myClass.cls.place !== '' &&
                          myClass.cls.place !== place
                        ) {
                          onPopoverOpen();
                        }
                      }}
                      onBlur={() => {
                        onPopoverClose();
                      }}
                      placeholder={myClass.cls.place}
                      {...textareaProps}
                    />
                  </Box>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverBody>
                    {myClass.cls.place}を教室に設定しますか？
                  </PopoverBody>
                  <PopoverFooter display="flex" justifyContent="flex-end">
                    <ButtonGroup size="sm">
                      <Button
                        variant="outline"
                        onClick={() => {
                          setDoSuggest(false);
                          onPopoverClose();
                        }}
                      >
                        キャンセル
                      </Button>
                      <Button
                        colorScheme="blue"
                        onClick={() => {
                          setPlace(myClass.cls.place);
                          onPopoverClose();
                        }}
                      >
                        設定
                      </Button>
                    </ButtonGroup>
                  </PopoverFooter>
                </PopoverContent>
              </Popover>
            </FormControl>
            <FormControl>
              <FormLabel>時限</FormLabel>
              <PeriodForm
                onChange={(values) =>
                  setPeriods(values.map((v) => StrToPeriod(String(v))))
                }
                value={periods?.map((p) => PeriodToStr(p, true))}
                choices={
                  searchOptionsLoadable
                    .valueMaybe()
                    ?.find((filter) => filter.name === 'period')?.choices ?? []
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>色</FormLabel>
              <ColorSelector
                options={Object.values(FlatColors)}
                value={color}
                onChange={setColor}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Moodle ID</FormLabel>
              <Input
                value={moodleId ?? ''}
                onChange={(e) => setMoodleId(e.target.value)}
                onBlur={handleBlur}
                placeholder={String(myClass.cls.moodle_id ?? '')}
              />
              <FormHelperText>
                MoodleのURLを入力すると自動で設定されます。
              </FormHelperText>
            </FormControl>
          </VStack>
        </BottomDrawerBody>
      </BottomDrawer>
    </>
  );
});
