export type AnnouncementModel = {
  id: number;
  category: 'update' | 'new-feature' | 'important';
  title: string;
  /* 未読/既読の場合はboolean, ログインしていない場合はnull */
  is_read: boolean | null;
  content: string;
  created_at: string;
  updated_at: string;
};

export const getEmptyAnnouncementModel = (): AnnouncementModel => ({
  id: 0,
  category: 'update',
  title: '',
  content: '',
  is_read: null,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
});

export const getDummyAnnouncementModel = (
  _?: unknown,
  i?: number
): AnnouncementModel => ({
  id: (i || 0) + 1,
  category: 'update',
  title: 'タイトル',
  content: '内容',
  is_read: null,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
});
