import {
  Box,
  Button,
  CardBody,
  Collapse,
  Heading,
  HeadingProps,
  Link,
  LinkProps,
  ListItem,
  ListItemProps,
  ListProps,
  OrderedList,
  Text,
  TextProps,
  UnorderedList,
} from '@chakra-ui/react';
import { MyClassInfo } from 'app/hupassApi';
import { ClassCard } from 'components/common/card/ClassCard';
import SectionContents from 'components/common/section/SectionContents';
import SectionHeading from 'components/common/section/SectionHeading';
import { memo as reactMemo, useEffect, useRef, useState } from 'react';
import { IoCheckmark, IoPencil } from 'react-icons/io5';
import Markdown, { Options } from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import MarkdownEditor from '../../common/form/MarkdownEditor';

export type MemoProps = {
  myClass: MyClassInfo;
  update?: (
    newMyClass: MyClassInfo,
    controller?: AbortController
  ) => Promise<void>;
};

function MarkdownH1(props: HeadingProps) {
  return <Heading as={'h4'} fontSize={'2xl'} my={4} {...props} />;
}
function MarkdownH2(props: HeadingProps) {
  return <Heading as={'h5'} fontSize={'xl'} my={4} {...props} />;
}
function MarkdownH3(props: HeadingProps) {
  return <Heading as={'h6'} fontSize={'lg'} my={4} {...props} />;
}
function MarkdownParagraph(props: TextProps) {
  return <Text fontSize={'md'} my={2} {...props} />;
}
function MarkdownLink(props: LinkProps) {
  return <Link color={'chakra-link-color'} {...props} />;
}
function MarkdownOl(props: ListProps) {
  return <OrderedList {...props} />;
}
function MarkdownUl(props: ListProps) {
  return (
    <UnorderedList
      {...props}
      sx={{
        '.task-list-item': {
          listStyle: 'none',
          ml: -4,
          input: {
            mr: 2,
          },
        },
      }}
    />
  );
}
function MarkdownLi(props: ListItemProps) {
  return <ListItem {...props} />;
}

const defaultMemo = '';

export default reactMemo(function MarkdownMemo(props: MemoProps) {
  const { myClass, update } = props;
  const [memo, setMemo] = useState(myClass.memo);
  const [edit, setEdit] = useState(false);
  const controller = useRef<AbortController>();

  useEffect(() => {
    controller.current = new AbortController();

    return () => {
      controller.current?.abort();
    };
  }, []);

  useEffect(() => {
    if (!edit && myClass.memo !== memo) {
      update?.({ ...myClass, memo }, controller.current).catch(() => {});
    }
  }, [edit, memo, update, myClass]);

  return (
    <>
      <SectionHeading display={'flex'}>
        講義メモ
        <Button
          alignSelf={'flex-end'}
          ml={'auto'}
          variant={'link'}
          leftIcon={edit ? <IoCheckmark /> : <IoPencil />}
          onClick={() => setEdit(!edit)}
        >
          {edit ? '保存' : '編集'}
        </Button>
      </SectionHeading>
      <SectionContents>
        <Box m={-2}>
          <Collapse in={edit}>
            <Box p={2}>
              <ClassCard classColor={myClass.color}>
                <CardBody py={2}>
                  <MarkdownEditor
                    value={memo}
                    onChange={setMemo}
                    variant={'unstyled'}
                  />
                </CardBody>
              </ClassCard>
            </Box>
          </Collapse>
        </Box>
      </SectionContents>
      <SectionContents>
        <ClassCard classColor={myClass.color}>
          <CardBody pt={2} minH={20}>
            <Markdown
              components={{
                h1: MarkdownH1,
                h2: MarkdownH2,
                h3: MarkdownH3,
                a: MarkdownLink,
                p: MarkdownParagraph,
                ol: MarkdownOl,
                ul: MarkdownUl,
                li: MarkdownLi,
              }}
              allowedElements={[
                'h1',
                'h2',
                'h3',
                'a',
                'p',
                'br',
                'ul',
                'ol',
                'li',
                'strong',
                'em',
                'input',
              ]}
              remarkPlugins={[remarkGfm, remarkBreaks]}
            >
              {memo || defaultMemo}
            </Markdown>
          </CardBody>
        </ClassCard>
      </SectionContents>
    </>
  );
});

export const DetailMemoMarkdown = (props: Readonly<Options>) => (
  <Markdown
    components={{
      h1: MarkdownH1,
      h2: MarkdownH2,
      h3: MarkdownH3,
      a: MarkdownLink,
      p: MarkdownParagraph,
      ol: MarkdownOl,
      ul: MarkdownUl,
      li: MarkdownLi,
    }}
    allowedElements={[
      'h1',
      'h2',
      'h3',
      'a',
      'p',
      'br',
      'ul',
      'ol',
      'li',
      'strong',
      'em',
      'input',
    ]}
    remarkPlugins={[remarkGfm, remarkBreaks]}
    {...props}
  />
);
