import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { searchOptionsState } from 'app/recoil/searchOptionsState';
import { memo, type ChangeEventHandler } from 'react';
import { useRecoilValue } from 'recoil';

export interface SemesterSelectorProps {
  value: string;
  onChange: React.Dispatch<string>;
}

const YearSelector = memo(function YearSelector(props: SemesterSelectorProps) {
  const { value, onChange } = props;
  const searchOptions = useRecoilValue(searchOptionsState);

  const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    onChange(e.target.value);
  };

  return (
    <FormControl>
      <FormLabel>年度</FormLabel>
      <Select value={value} onChange={handleChange}>
        {searchOptions.flatMap(({ name, choices }) =>
          name === 'year'
            ? choices.map(([year, label]) => (
                <option key={year} value={year}>
                  {label}
                </option>
              ))
            : []
        )}
      </Select>
    </FormControl>
  );
});

export default YearSelector;
