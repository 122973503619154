import hupassApi, { Paginated } from 'app/hupassApi';
import { errorHandler } from 'components/common/toast/toast';
import { AnnouncementModel } from 'models/AnnouncementModel';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';

// カスタムイベントのために型を拡張
declare global {
  interface DocumentEventMap {
    onreadannouncement: CustomEvent<string>;
  }
}

export function useAnnouncements(defaultParams: URLSearchParams) {
  const [announcements, setAnnouncements] = useState<AnnouncementModel[]>([]);
  const [pagination, setPagination] = useState<Omit<Paginated, 'results'>>();
  const [cookies] = useCookies(['token']);
  const [fetching, setFetching] = useState(false);
  const controllerRef = useRef(new AbortController());

  const fetchAnnouncements = useCallback(
    (page = 1, isSetFetching = true) => {
      controllerRef.current.abort();
      // キャンセルの場合にバグるのであえて遅延させる
      isSetFetching && setTimeout(() => setFetching(true));
      controllerRef.current = new AbortController();
      hupassApi
        .listAnnouncements(
          String(cookies.token || ''),
          new URLSearchParams({
            ...Object.fromEntries(defaultParams),
            page: String(page),
          }),
          controllerRef.current
        )
        .then((res) => {
          const { results, ...pagination } = res.data;
          setAnnouncements(results);
          setPagination(pagination);
        })
        .catch(errorHandler)
        .finally(() => {
          isSetFetching && setFetching(false);
        });
    },
    [cookies.token, defaultParams]
  );

  // お知らせを既読にするために単一のお知らせをフェッチ
  const fetchAnnouncement = useCallback(
    (id: number) => {
      hupassApi
        .getAnnouncement(id, String(cookies.token || ''))
        .then((res) => {
          setAnnouncements((announcements) =>
            announcements.map((announcement) =>
              announcement.id === id ? res.data : announcement
            )
          );
          document.dispatchEvent(
            new CustomEvent('onreadannouncement', { detail: String(id) })
          );
        })
        .catch(errorHandler);
    },
    [cookies.token]
  );

  useEffect(() => {
    fetchAnnouncements();
  }, [fetchAnnouncements]);

  useEffect(() => {
    const listener = () => fetchAnnouncements(pagination?.current_page, false);
    document.addEventListener('onreadannouncement', listener);

    return () => document.removeEventListener('onreadannouncement', listener);
  }, [fetchAnnouncements, pagination?.current_page]);

  return {
    announcements,
    pagination,
    fetching,
    fetchAnnouncements,
    fetchAnnouncement,
  };
}
