import { Button } from '@chakra-ui/react';
import routes from 'app/routes';
import MainContainer from 'components/common/container/MainContainer';
import Section from 'components/common/section/Section';
import SectionContents from 'components/common/section/SectionContents';
import SectionHeading from 'components/common/section/SectionHeading';
import SectionStack from 'components/common/section/SectionStack';
import { Link, useParams } from 'react-router-dom';
import { AnnouncementForm } from './AnnouncementForm';
import { useAnnouncement } from './useAnnouncement';
import { IoChevronBack } from 'react-icons/io5';

export function EditAnnouncement() {
  const { id } = useParams();
  const [announcement, updateAnnouncement] = useAnnouncement(id);

  return (
    <MainContainer>
      <Button
        colorScheme="blue"
        variant={'link'}
        leftIcon={<IoChevronBack />}
        as={Link}
        to={routes.announcements_list}
        mt={4}
      >
        一覧に戻る
      </Button>
      <SectionStack>
        <Section>
          <SectionHeading>お知らせの編集</SectionHeading>
          <SectionContents>
            <AnnouncementForm
              announcement={announcement}
              onSubmit={updateAnnouncement}
              submitButton={
                <Button type="submit" colorScheme="orange">
                  保存
                </Button>
              }
            />
          </SectionContents>
        </Section>
      </SectionStack>
    </MainContainer>
  );
}
