import { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

export function AutoFade(props: PropsWithChildren) {
  const { children } = props;

  return (
    <motion.div
      initial={{ opacity: 0, translateY: 80 }}
      whileInView={{ opacity: 1, translateY: 0 }}
      transition={{
        type: 'spring',
        bounce: 0.4,
        duration: 0.8,
      }}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
}
