import axios from 'axios';
import { AnnouncementModel } from 'models/AnnouncementModel';

// 主にサーバーとの通信用のAPI

/** 成績 */
export type Grade =
  | 'ap'
  | 'a'
  | 'am'
  | 'bp'
  | 'b'
  | 'bm'
  | 'cp'
  | 'c'
  | 'd'
  | 'dm'
  | 'f';

/** 成績情報の成績人数のみ */
export type Grades = {
  [keys in Grade]: number;
};

/** 成績情報 */
export type GradeInfo = Grades & {
  /** 成績情報データベースのID */
  id: number;
  /** 科目名 */
  subject: string;
  /** 講義題目 */
  lecture: string;
  /** グループ */
  group: string;
  /** 担当教員 */
  teacher: string;
  /** 開講年度 */
  year: string;
  /** 開講期間 */
  semester: string;
  /** 学部 */
  faculty: string;
  /** 受講生徒数 */
  numOfStudents: number;
  /** 平均GPA */
  gpa: number;
};

/** 時限情報 */
export interface ClassPeriod {
  id?: number;
  period: number;
  day: number;
}

/** ClassInfoのキーワード */
export interface ClassKeyword {
  id: number;
  cls: number;
  keyword: string;
}

export interface Urls {
  syllabus: string | null;
  moodle: string | null;
  elms: string | null;
}

/** クラス情報 */
export interface ClassInfo {
  /** クラスデータベースID */
  id: number;
  /** 科目名 */
  subject: string;
  /** 講義題目 */
  theme: string;
  /** 登録しているかどうか */
  is_enrolled: boolean;
  /** 担当教員 */
  teacher: string;
  /** 開講年度 */
  year: number;
  /** 開講期間 */
  semester: string;
  /** 講義室 */
  place: string;
  /** 対象年次(低) */
  elig_min: number | null;
  /** 対象年次(高) */
  elig_max: number | null;
  /** 対象学科 */
  elig_dep: string;
  /** 単位数 */
  credit: number;
  /** 科目種別 */
  sort: string;
  /** 大分類コード */
  big_code: string;
  /** ナンバリングコード */
  code: number;
  /** 時間割番号 */
  cls_id: number | null;
  /** キーワード */
  keywords: ClassKeyword[];
  /** 時限 */
  periods: ClassPeriod[];
  /** 学部 */
  faculty: string;
  /** Moodle ID */
  moodle_id: number;
  /** 成績情報 */
  grade_info: GradeInfo | null;
  /** URL */
  urls: Urls;
}

/** 登録したクラス情報 */
export interface MyClassInfo {
  /** クラス登録データベースのID */
  id?: number;
  /** ユーザーID */
  user?: string;
  /** クラス情報 */
  cls: ClassInfo;
  /** クラステーマカラー */
  color: string;
  /** メモ */
  memo: string;
  /** カスタマイズした科目名 */
  c_subject: string;
  /** カスタマイズした講義題目 */
  c_theme: string;
  /** カスタマイズしたMoodle ID */
  c_moodle_id: number | null;
  /** カスタマイズしたMoodle URL */
  c_moodle_url: string | null;
  /** カスタマイズした教員 */
  c_teacher: string;
  /** カスタマイズした場所 */
  c_place: string;
  /** カスタマイズした時限 */
  c_periods: ClassPeriod[];
}

/** 絞り込みの名前 */
export type SearchKey =
  | 'period'
  | 'year'
  | 'semester'
  | 'faculty'
  | 'keyword'
  | 'order_by';

/** 絞り込みのクエリ */
export type SearchQuery = Partial<Record<SearchKey, string[]>>;

/** 絞り込みオプション */
export interface SearchOption {
  /** 絞り込みの名前 */
  name: SearchKey;
  /** 絞り込みのラベル */
  label: string;
  /** 単選択か複選択か */
  type: 'choice' | 'multiple_choice';
  /** 選択肢 */
  choices: Array<[string, string] | [number, number]>;
}

/** 並び替えオプション */
export interface SortOption {
  /** 並び替えの名前 */
  name: string;
  /** 並び替えのラベル */
  label: string;
  /** デフォルトで選択されているかどうか */
  default: boolean;
}

/** ページネーション付き情報 */
export interface Paginated<T = unknown> {
  /** リンク */
  links?: {
    /** 次ページのリンク */
    next: string;
    /** 前ページのリンク */
    previous: string;
    /** ページなしリンク */
    pageless: string;
  };
  /** 合計ヒット数 */
  count: number;
  /** 合計ページ数 */
  total_pages: number;
  /** 現在のページ */
  current_page: number;
  /** クラス情報リスト */
  results: T[];
}

/** ユーザー情報 */
export interface UserInfo {
  /** ユーザーのメールアドレス */
  email: string;
  /** ユーザーアイコンのURL */
  picture_url: string;
  /** ユーザー名 */
  username: string;
  /** 管理者か */
  is_superuser?: boolean;
}

/** 第二外国語 */
export type SecondLanguage = 'de' | 'ch' | 'kr' | 'fr' | 'ru' | 'es' | null;

/** 必修登録用のユーザー情報 */
export interface GeneralUserInfo {
  /** 開講年度 */
  year: number;
  /** 文理 */
  department: number;
  /** 基礎クラス */
  base_class: number;
  /** 第二外国語 */
  second_language: SecondLanguage;
  /** 第二外国語クラス */
  second_language_class: number;
  /** ドイツ語サブクラス */
  second_language_subclass: number;
  /** 英語Ⅰクラス */
  eng1_class: number;
  /** 英語Ⅱクラス */
  eng2_class: number;
  /** 学生番号下2桁 */
  student_id_last2: number;
  /** 自然科学実験の科目 */
  science_exp_class?: Array<string>;
}

/** @deprecated かもしれない */
export interface FilterQuery {
  /** クラス情報のID */
  id?: number;
  /** 開講年度 */
  year?: number;
  /** 開講期間 */
  semester?: string;
  /** 期間のインデックス */
  semester_int?: number;
  /** 時限 */
  period?: ClassPeriod[];
}

/** Hupass BackendのURI */
export const baseURL = import.meta.env.VITE_BACKEND_URI ?? '/api/';

/** 通信用AxiosInstance */
export const hupassAxios = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json;charset=utf-8;',
    'Access-Control-Allow-Origin': '*',
  },
  paramsSerializer: {
    indexes: null,
  },
});

const generateAuthHeader = (token: string) => ({
  Authorization: `Token ${token}`,
});

/**
 * サーバーと通信するための関数
 * @todo ゲスト動作
 * */
const hupassApi = {
  /**
   * 謎
   * @param data
   * @returns
   */
  // GetToken: async (data: {}) => hupassAxios.post('/token/', data),

  ConvertToken: async (data: {
    code: string;
    provider: string;
    redirect_uri: string;
  }) => hupassAxios.post('/token/', data),

  GetUserInfo: async (user: string, token: string) =>
    hupassAxios.get<UserInfo>(`/user/${user}/`, {
      headers: generateAuthHeader(token),
    }),

  /**
   * IDからクラス情報を取得
   * @param id クラスのID
   * @returns クラス情報
   */
  GetClass: async (id: number) => hupassAxios.get<ClassInfo>(`/classes/${id}/`),

  /**
   * クラスのIDから登録したクラスの情報を取得
   * @param id クラスのID
   * @returns 登録したクラスの情報
   */
  GetMyClass: async (id: number, token: string, controller?: AbortController) =>
    hupassAxios.get<MyClassInfo>(`/myclass/${id}/`, {
      headers: generateAuthHeader(token),
      signal: controller?.signal,
    }),

  /**
   * フィルターのオプションを取得
   * @returns フィルターのオプションのリスト
   */
  GetSearchOptions: async () =>
    hupassAxios.get<SearchOption[]>('/search-options/'),

  /**
   * 並び替えのオプションを取得
   * @returns 並び替えのオプションのリスト
   */
  GetSortOptions: async () => hupassAxios.get<SortOption[]>('/sort-options/'),

  /**
   * クラス一覧を検索
   * @param params 検索条件
   * @returns 検索条件に一致するクラスの一覧
   */
  ListClasses: async (params?: URLSearchParams, controller?: AbortController) =>
    hupassAxios.get<Paginated<ClassInfo>>('/classes/', {
      params,
      signal: controller?.signal,
    }),

  /**
   * 登録したクラスの一覧を取得
   * @returns 登録したクラスのリスト
   */
  ListMyClasses: async (
    params: { page?: number },
    token: string,
    controller?: AbortController
  ) =>
    hupassAxios.get<Paginated<MyClassInfo>>('/myclass/', {
      params,
      headers: generateAuthHeader(token),
      signal: controller?.signal,
    }),

  /**
   * クラスを登録
   * @param data 登録するクラスの情報
   * @returns ？
   */
  CreateMyClass: async (
    data: Partial<Omit<MyClassInfo, 'cls'>> & { cls_id: number },
    token: string,
    controller?: AbortController
  ) =>
    hupassAxios.post<MyClassInfo>('/myclass/', data, {
      headers: generateAuthHeader(token),
      signal: controller?.signal,
    }),

  /**
   * クラスの複数登録
   * @param data 登録するクラスの情報のリスト
   */
  CreateMultipleMyClasses: async (
    data: Array<Partial<Omit<MyClassInfo, 'cls'>> & { cls_id: number }>,
    token: string,
    controller?: AbortController
  ) =>
    hupassAxios.post<MyClassInfo[]>('/myclass/multiple/', data, {
      headers: generateAuthHeader(token),
      signal: controller?.signal,
    }),

  /**
   * クラスの登録解除
   * @param id 登録解除するクラスのID
   * @returns ？
   */
  DeleteMyClass: async (
    id: number,
    token: string,
    controller?: AbortController
  ) =>
    hupassAxios.delete<void>(`/myclass/${id}/`, {
      headers: generateAuthHeader(token),
      signal: controller?.signal,
    }),

  /**
   * 登録したクラスの情報を更新
   * @param data クラス情報
   * @returns ？
   */
  UpdateMyClass: async (
    data: Partial<Omit<MyClassInfo, 'cls'>> & { cls_id: number },
    token: string,
    controller?: AbortController
  ) =>
    hupassAxios.patch<MyClassInfo>(`/myclass/`, data, {
      headers: generateAuthHeader(token),
      signal: controller?.signal,
    }),

  /**
   * 必修科目の一覧を取得
   * @param data 必修登録用のユーザー情報
   * @returns 必修科目のリスト
   */
  GetRequiredClasses: async (
    data: GeneralUserInfo,
    // token: string,
    controller?: AbortController
  ) =>
    hupassAxios.post<ClassInfo[]>('/general/', data, {
      // headers: generateAuthHeader(token),
      signal: controller?.signal,
    }),

  createAnnouncement: async (
    data: AnnouncementModel,
    token: string,
    controller?: AbortController
  ) => {
    return hupassAxios.post<AnnouncementModel>('/announcements/', data, {
      headers: generateAuthHeader(token),
      signal: controller?.signal,
    });
  },

  listAnnouncements: async (
    token?: string,
    params?: URLSearchParams,
    controller?: AbortController
  ) => {
    return hupassAxios.get<Paginated<AnnouncementModel>>('/announcements/', {
      ...(token && { headers: generateAuthHeader(token) }),
      params,
      signal: controller?.signal,
    });
  },

  getAnnouncement: async (
    id: number,
    token?: string,
    controller?: AbortController
  ) => {
    return hupassAxios.get<AnnouncementModel>(`/announcements/${id}/`, {
      ...(token && { headers: generateAuthHeader(token) }),
      signal: controller?.signal,
    });
  },

  updateAnnouncement: async (
    data: AnnouncementModel,
    token: string,
    controller?: AbortController
  ) => {
    const { id, ...rest } = data;
    return hupassAxios.patch<AnnouncementModel>(`/announcements/${id}/`, rest, {
      headers: generateAuthHeader(token),
      signal: controller?.signal,
    });
  },
};

export default hupassApi;
